/* eslint-disable jsx-a11y/alt-text */
import { Grid, Stack, CircularProgress } from "@mui/material";
import React, { useMemo, useState, Suspense, lazy } from "react";
import { EDisplayContactUs } from "./constants";
import Footer from "./components/Footer";
import ButtonGroup from "./components/ButtonGroup";

const SendMessage = lazy(() => import("./components/SendMessage"));
const BookCall = lazy(() => import("./components/BookCall/BookCall"));

const MemoizedFooter = React.memo(Footer);
const MemoizedButtonGroup = React.memo(ButtonGroup);

const ContactUs = () => {
  const [render, setRender] = useState<EDisplayContactUs>(
    EDisplayContactUs.SENDMESSAGE
  );

  const handleRender = (display: EDisplayContactUs) => {
    setRender(display);
  };

  const renderComponent = useMemo(() => {
    switch (render) {
      case EDisplayContactUs.SENDMESSAGE:
        return <SendMessage />;
      case EDisplayContactUs.BOOKACALL:
        return <BookCall />;
      default:
        return null;
    }
  }, [render]);

  return (
    <Grid
      display="flex"
      justifyContent={{
        xl: "space-between",
        lg: "space-between",
        md: "center",
        sm: "center",
        xs: "center",
      }}
      sx={{ userSelect: "none" }}
      flexDirection={{
        xl: "row",
        lg: "row",
        md: "column-reverse",
        sm: "column-reverse",
        xs: "column-reverse",
      }}
    >
      <MemoizedFooter />
      <Grid
        width={{ xl: "50%", lg: "50%", md: "100%", sm: "100%", xs: "100%" }}
        ml={{ xl: 2, lg: 0, md: 0, sm: 0, xs: 0 }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        mb={{ xl: 0, lg: 0, md: 5, sm: 5, xs: 5 }}
        mt={{ xl: 0, lg: 0, md: 5, sm: 5, xs: 5 }}
      >
        <Stack
          direction="column"
          spacing={{ xl: 3, lg: 1, md: 3, sm: 3, xs: 3 }}
        >
          <MemoizedButtonGroup render={render} handleRender={handleRender} />
          <Grid>
            <Suspense fallback={<CircularProgress />}>
              {renderComponent}
            </Suspense>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
