/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import BG from "../../assets/images/backgrounds/footerbg.png";
import { useTextStyle } from "../../contexts/TextStyleContext";
import OutlinedButton from "../buttons/outlinedbutton/OutlinedButton";
import theme from "../../theme/theme";
import logo from "../../assets/svg/icons/neuranestfootericon.svg";

const Footer = () => {
  const { texts } = useTextStyle();

  return (
    <Grid
      container
      px={{ xl: 11, lg: 11, md: 5, sm: 3, xs: 2 }}
      py={8}
      sx={{
        minHeight: 1013,
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderTopLeftRadius: {
          xs: "40px",
          sm: "40px",
          md: "117px",
          lg: "117px",
          xl: "117px",
        },
        borderTopRightRadius: {
          xs: "40px",
          sm: "40px",
          md: "117px",
          lg: "117px",
          xl: "117px",
        },
        overflow: "hidden",
        userSelect: "none",
      }}
    >
      <Grid
        display="flex"
        flexDirection={{
          xl: "row",
          lg: "row",
          md: "column",
          sm: "column",
          xs: "column",
        }}
        justifyContent="space-between"
        width="100%"
      >
        <Grid
          maxWidth={{ xl: 600, lg: 600, md: "100%", sm: "100%", xs: "100%" }}
        >
          <Box>
            <Typography
              textAlign={{
                xl: "start",
                lg: "start",
                md: "center",
                sm: "center",
                xs: "center",
              }}
              sx={[
                texts.Display_Title,
                {
                  color: "#38405B",
                  fontSize: { xl: 64, lg: 64, md: 64, sm: 32, xs: 32 },
                },
              ]}
            >
              Got a project in mind?
            </Typography>
            <Typography
              textAlign={{
                xl: "start",
                lg: "start",
                md: "center",
                sm: "center",
                xs: "center",
              }}
              sx={[
                texts.Display_Title,
                {
                  color: "#38405B",
                  fontSize: { xl: 64, lg: 64, md: 64, sm: 32, xs: 32 },
                },
              ]}
            >
              Let’s talk about it!
            </Typography>
          </Box>
          <Box
            mt={8}
            display="flex"
            justifyContent={{
              xl: "flex-start",
              lg: "flex-start",
              md: "center",
              sm: "center",
              xs: "center",
            }}
          >
            <OutlinedButton text="GET IN TOUCH" />
          </Box>
        </Grid>
        <Grid pr={{ xl: 20.5, lg: 20.5, md: 0, sm: 0, xs: 0 }}>
          <Grid>
            <Typography
              fontWeight={500}
              fontSize="32px"
              lineHeight="50px"
              color="#4D4D4D"
            >
              Scope
            </Typography>
          </Grid>
          <Stack direction="row" spacing={8} mt={3}>
            <Stack direction="column" spacing={3}>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
            </Stack>
            <Stack direction="column" spacing={3}>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={[
                    texts.Body_Regular_24_Medium,
                    {
                      color: theme.palette.customColors.grey.bodyDark,
                      fontSize: { xl: 24, lg: 24, md: 24, sm: 12, xs: 12 },
                    },
                  ]}
                >
                  Mobile Development
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Grid mt={8} width="100%">
        <Grid display="flex" justifyContent="flex-end">
          <Typography
            sx={[
              texts.Display_Title,
              {
                color: "#293159",
                fontSize: { xl: 64, lg: 64, md: 32, sm: 24, xs: 24 },
              },
            ]}
          >
            info@neurastudios.com
          </Typography>
        </Grid>
        <Grid
          mt={3}
          height={{ xl: 200, lg: 200, md: 200, sm: 100, xs: 100 }}
          width="100%"
        >
          <img width="100%" src={logo} />
        </Grid>
        <Grid mt={12.5} display="flex" justifyContent="space-between">
          <Grid>
            <Typography sx={[texts.Link_Body_Small, { color: "#38405B" }]}>
              © 2023 Neura Studios Agency. All rights reserved.
            </Typography>
          </Grid>
          <Grid>
            <Typography sx={[texts.Link_Body_Small, { color: "#38405B" }]}>
              Designed by Neurastudios
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
