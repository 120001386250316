import { Box, Grid, Stack } from "@mui/material";
import React, { useMemo } from "react";
import FormButton from "../../../components/buttons/formbutton/FormButton";
import { EDisplayContactUs } from "../constants";

export interface IButtonGroupProps {
  render: EDisplayContactUs;
  handleRender: (display: EDisplayContactUs) => void;
}

const ButtonGroup = ({ render, handleRender }: IButtonGroupProps) => {
  const renderButtonGroup = useMemo(() => {
    return (
      <Stack
        display="flex"
        direction={{
          xl: "row",
          lg: "row",
          md: "row",
          sm: "row",
          xs: "column",
        }}
        alignItems="center"
        spacing={3}
      >
        <Box>
          <FormButton
            text={"Send message"}
            isActive={render === EDisplayContactUs.SENDMESSAGE}
            onClick={() => handleRender(EDisplayContactUs.SENDMESSAGE)}
          />
        </Box>
        <Box>
          <FormButton
            text={"Book a call"}
            isActive={render === EDisplayContactUs.BOOKACALL}
            onClick={() => handleRender(EDisplayContactUs.BOOKACALL)}
          />
        </Box>
      </Stack>
    );
  }, [handleRender, render]);

  return (
    <Grid display="flex" justifyContent="center">
      {renderButtonGroup}
    </Grid>
  );
};

export default ButtonGroup;
