import { ERoutes } from "../../router/constants";

export interface ITabs {
  label: string;
  navigate: ERoutes;
}

export const Tabs: ITabs[] = [
  { label: "Services", navigate: ERoutes.SERVICES },
  { label: "Work", navigate: ERoutes.WORK },
  { label: "Contact Us", navigate: ERoutes.CONTACTUS },
];
