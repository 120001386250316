/* eslint-disable jsx-a11y/alt-text */
import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTextStyle } from "../../../contexts/TextStyleContext";
import { baseFontProperties } from "../../../theme/_config";

import BG from "../../../assets/images/backgrounds/footerbg.png";
import logo from "../../../assets/svg/icons/neuranestfootericon.svg";

const Footer = () => {
  const { texts } = useTextStyle();
  const render = useMemo(() => {
    return (
      <Grid
        width={{ xl: "50%", lg: "50%", md: "100%", sm: "100%", xs: "100%" }}
        my={{ xl: 2, lg: 2, md: 0, sm: 0, xs: 0 }}
        py={{ xl: 10, lg: 10, md: 10, sm: 2, xs: 2 }}
        px={{ xl: 8, lg: 8, md: "5%", sm: "5%", xs: "5%" }}
        sx={{
          height: { xl: 938, lg: 938, md: 938, sm: "100%", xs: "100%" },
          backgroundImage: `url(${BG})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderTopRightRadius: {
            xl: "16px",
            lg: "16px",
            md: 0,
            sm: 0,
            xs: 0,
          },
          borderBottomRightRadius: {
            xl: "16px",
            lg: "16px",
            md: 0,
            sm: 0,
            xs: 0,
          },
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid maxWidth={{ xl: 718, lg: 718, md: 718, sm: "100%", xs: "100%" }}>
          <Grid pr={{ xl: 15, lg: 15, md: 15, sm: "5%", xs: "2%" }}>
            <Typography
              sx={[
                texts.Display_Title,
                {
                  color: baseFontProperties.color.grey,
                  fontSize: {
                    xl: "64px",
                    lg: "52px",
                    md: "64px",
                    sm: "64px",
                    xs: "52px",
                  },
                  lineHeight: {
                    xl: "78px",
                    lg: "78px",
                    md: "78px",
                    sm: "78px",
                    xs: "64px",
                  },
                },
              ]}
            >
              Where great design fuels business success.
            </Typography>
          </Grid>
          <Grid mt={{ xl: 8, lg: 8, md: 8, sm: 8, xs: 4 }}>
            <Typography
              sx={[
                texts.Body_Regular_24_Medium,
                { color: baseFontProperties.color.grey },
              ]}
            >
              Guaranteed next-day design from top global talent. Wave goodbye to
              recruitment, training and management. Say hello to superior design
              from a dedicated team of designers.
            </Typography>
          </Grid>
        </Grid>
        <Grid width="100%" mt={{ xl: 0, lg: 0, md: 2, sm: 2, xs: 2 }}>
          <Grid display="flex" justifyContent="flex-end">
            <Typography
              color="#293159"
              fontSize={{
                xl: "28px",
                lg: "28px",
                md: "28px",
                sm: "28px",
                xs: "24px",
              }}
              lineHeight="34px"
              fontWeight={500}
              fontFamily="Montserrat, sans-serif"
            >
              info@neurastudios.com
            </Typography>
          </Grid>
          <Grid width="97%" mt={1} display="flex" justifyContent="center">
            <img height={87} width="97%" src={logo} />
          </Grid>
          <Grid
            display="flex"
            justifyContent="space-between"
            flexDirection={{
              xl: "row",
              lg: "row",
              md: "row",
              sm: "column",
              xs: "column",
            }}
            mt={5}
          >
            <Grid>
              <Typography sx={[texts.Link_Body_Small, { color: "#38405B" }]}>
                © 2023 Neura Studios Agency. All rights reserved.
              </Typography>
            </Grid>
            <Grid>
              <Typography sx={[texts.Link_Body_Small, { color: "#38405B" }]}>
                Designed by Neurastudios
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [
    texts.Body_Regular_24_Medium,
    texts.Display_Title,
    texts.Link_Body_Small,
  ]);

  return render;
};

export default Footer;
