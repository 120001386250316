import { Box, Grid, CircularProgress } from "@mui/material";
import React, { Suspense, lazy } from "react";

// Lazy load the components
const DynamicInnovation = lazy(
  () => import("./parts/dynamicinnovation/DynamicInnovation")
);
const FutureForwardExperience = lazy(
  () => import("./parts/futureforwardexperience/FutureForwardExperience")
);
const Carousel = lazy(() => import("./parts/carousel/Carousel"));
const OurAdvantages = lazy(() => import("./parts/ouradvantages/OurAdvantages"));
const OurServices = lazy(() => import("./parts/ourservices/OurServices"));
const HowItWorks = lazy(() => import("./parts/howitworks/HowItWorks"));
const OurPrinciples = lazy(() => import("./parts/ourprinciples/OurPrinciples"));
const Collaborate = lazy(() => import("./parts/collaborate/Collaborate"));

// Memoize the components to avoid unnecessary re-renders
const MemoizedDynamicInnovation = React.memo(DynamicInnovation);
const MemoizedFutureForwardExperience = React.memo(FutureForwardExperience);
const MemoizedCarousel = React.memo(Carousel);
const MemoizedOurAdvantages = React.memo(OurAdvantages);
const MemoizedOurServices = React.memo(OurServices);
const MemoizedHowItWorks = React.memo(HowItWorks);
const MemoizedOurPrinciples = React.memo(OurPrinciples);
const MemoizedCollaborate = React.memo(Collaborate);

const LandingPage = () => {
  return (
    <Grid sx={{ userSelect: "none" }}>
      <Suspense fallback={<CircularProgress />}>
        <MemoizedDynamicInnovation />
        <Box bgcolor="#F7F7F7">
          <MemoizedFutureForwardExperience />
        </Box>
        <Box>
          <MemoizedOurServices />
        </Box>
        <Box
          bgcolor="#F7F7F7"
          sx={{ whiteSpace: "nowrap", overflow: "hidden", display: "block" }}
          width="100%"
        >
          <MemoizedCarousel />
        </Box>
        <Box>
          <MemoizedOurAdvantages />
        </Box>
        <Box>
          <MemoizedHowItWorks />
        </Box>
        <Box
          display={{
            xl: "flex",
            lg: "flex",
            md: "flex",
            sm: "none",
            xs: "none",
          }}
        >
          <MemoizedOurPrinciples />
        </Box>
         <Box>
          <MemoizedCollaborate />
        </Box>
      </Suspense>
    </Grid>
  );
};

export default LandingPage;
