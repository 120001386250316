import { Button } from "@mui/material";
import React from "react";

export interface IFormButtonProps {
  isActive: boolean;
  text: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  height?: string | number;
  width?: string | number;
}

const FormButton: React.FC<IFormButtonProps> = ({
  isActive,
  text,
  onClick,
  height = "50px",
  width,
}) => {
  return (
    <Button
      variant={isActive ? "contained" : "outlined"}
      sx={{
        bgcolor: isActive ? "#3C3C3C" : "transparent",
        color: isActive ? "#FFFFFF" : "#3C3C3C",
        borderRadius: "40px",
        border: "1px solid transparent",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 500,
        fontSize: "16px",
        textTransform: "none",
        height: { height },
        width: { width },
        "&:hover": {
          bgcolor: isActive ? "#3C3C3C" : "transparent",
          borderColor: "#3C3C3C",
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default FormButton;
