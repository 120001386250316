import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "../pages/landingpage/LandingPage";
import Services from "../pages/services/Services";
import Work from "../pages/work/Work";
import ContactUs from "../pages/contactus/ContactUs";
import Layout from "../layout/Layout";
import { ERoutes } from "./constants";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path={ERoutes.LANDINGPAGE} element={<LandingPage />} />
          <Route path={ERoutes.SERVICES} element={<Services />} />
          <Route path={ERoutes.WORK} element={<Work />} />
          <Route path={ERoutes.CONTACTUS} element={<ContactUs />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default AppRouter;
