import React from "react";
import { Button } from "@mui/material";

export interface IOutlinedButtonProps {
  text: string;
}

const OutlinedButton = ({ text }: IOutlinedButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        border: "1px solid #3C3C3C",
        borderRadius: "100px",
        padding: "16px 56px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "16px",
        color: "#3C3C3C",
        textTransform: "none", // Prevents text from being uppercased
        "&:hover": {
          borderColor: "#3C3C3C", // Keeps the border color the same on hover
        },
      }}
    >
      {text}
    </Button>
  );
};

export default OutlinedButton;
