import { Box, Grid, CircularProgress } from "@mui/material";
import React from "react";
import { Suspense, lazy } from "react";

// Lazy load the components
const MobileLockdown = lazy(
  () => import("./parts/mobilelockdown/MobileLockdown")
);
const OurProducts = lazy(() => import("./parts/ourproducts/OurProducts"));
const Collaborate = lazy(() => import("./parts/collaborate/Collaborate"));
const System = lazy(() => import("./parts/system/System"));

// Memoize the components to avoid unnecessary re-renders
const MemoizedMobileLockdown = React.memo(MobileLockdown);
const MemoizedOurProducts = React.memo(OurProducts);
const MemoizedCollaborate = React.memo(Collaborate);
const MemoizedSystem = React.memo(System);

const Work = () => {
  return (
    <Grid sx={{ userSelect: "none" }}>
      <Suspense fallback={<CircularProgress />}>
        <Box>
          <MemoizedMobileLockdown />
        </Box>
        <Box>
          <MemoizedOurProducts />
        </Box>
        <Box>
          <MemoizedSystem />
        </Box>
        <Box>
          <MemoizedCollaborate />
        </Box>
      </Suspense>
    </Grid>
  );
};

export default Work;
