export const calcSizeWidth = (size: number | string) =>
  `calc(100vw*${size}/1920)`;
export const calcSizeHeight = (size: number | string) =>
  `calc(100vh*${size}/1080)`;

export const baseFontProperties = {
  color: {
    text: "#181818",
    text_inverted: "#FFFFFF",
    neutral_100: "#FFFFFF",
    neutral_200: "#F4F5F7",
    neutral_300: "#E1E1E1",
    neutral_400: "#737581",
    neutral_500: "#4A4B53",
    neutral_600: "#000000",
    body_dark: "#808080",
    white2: "#F7F7F7",
    grey: "#3C3C3C",
  },
  sizes: {
    size_10: "10px",
    size_12: "12px",
    size_14: "14px",
    size_16: "16px",
    size_18: "18px",
    size_20: "20px",
    size_22: "22px",
    size_24: "24px",
    size_30: "30px",
    size_32: "32px",
    size_34: "34px",
    size_36: "36px",
    size_38: "38px",
    size_48: "48px",
    size_64: "64px",
    size_72: "72px",
    size_82: "82px",
    size_96: "96px",
    size_100: "100px",
    size_120: "120px",
  },
  weight: {
    normal: 400,
    medium: 500,
    bold: 600,
    xbold: 700,
    xxbold: 800,
    xxxbold: 900,
  },
  family: {
    inter: "Inter, sans-serif",
    montserrat: "Montserrat, sans-serif",
    karla: "Karla, sans-serif",
  },
  lineHeight: {
    size_14: "14px",
    size_16: "16px",
    size_20: "20px",
    size_22: "22px",
    size_24: "24px",
    size_26: "26px",
    size_28: "28px",
    size_30: "30px",
    size_32: "32px",
    size_38: "38px",
    size_40: "40px",
    size_42: "42px",
    size_44: "44px",
    size_48: "48px",
    size_60: "60px",
    size_78: "78px",
    size_80: "80px",
    size_96: "96px",
    size_100: "100px",
    size_112: "112px",
  },
};

export const createTextStyle = (
  color: keyof (typeof baseFontProperties)["color"],
  size: keyof (typeof baseFontProperties)["sizes"],
  weight: keyof (typeof baseFontProperties)["weight"],
  family: keyof (typeof baseFontProperties)["family"]
) => ({
  color: baseFontProperties.color[color],
  fontSize: baseFontProperties.sizes[size],
  fontWeight: baseFontProperties.weight[weight],
  fontFamily: baseFontProperties.family[family],
});
