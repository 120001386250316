import { Grid } from "@mui/material";
import React, { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  const layout = React.useMemo(() => {
    return (
      <Grid container style={{ flexDirection: "column" }}>
        <Grid display="flex" flex={1}>
          <Header />
        </Grid>

        <Grid
          sx={{
            minHeight: {
              xl: "100vh",
              lg: "100vh",
              md: "100vh",
              sm: "50vh",
              xs: "50vh",
            },
          }}
        >
          {children}
        </Grid>

        {location.pathname !== "/contact-us" && (
          <Grid
            display="flex"
            flex={1}
            bgcolor={
              location.pathname === "/services" ? "#000000" : "transparent"
            }
            width="100%"
          >
            <Footer />
          </Grid>
        )}
      </Grid>
    );
  }, [children, location.pathname]);

  return <>{layout}</>;
};

export default Layout;
