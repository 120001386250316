import { Box, Grid, CircularProgress } from "@mui/material";
import React, { Suspense, lazy } from "react";

// Lazy load the components
const OurServices = lazy(
  () => import("../landingpage/parts/ourservices/OurServices")
);
const OurMission = lazy(() => import("./parts/ourmission/OurMission"));
const Carousel = lazy(() => import("./parts/carousel/Carousel"));
const TechnologyStack = lazy(
  () => import("./parts/technologystack/TechnologyStack")
);
const DataCircle = lazy(() => import("./parts/datacircle/DataCircle"));
const OurTeam = lazy(() => import("./parts/ourteam/OurTeam"));
const Lets = lazy(() => import("./parts/lets/Lets"));

// Memoize the components to avoid unnecessary re-renders
const MemoizedOurServices = React.memo(OurServices);
const MemoizedOurMission = React.memo(OurMission);
const MemoizedCarousel = React.memo(Carousel);
const MemoizedTechnologyStack = React.memo(TechnologyStack);
const MemoizedDataCircle = React.memo(DataCircle);
const MemoizedOurTeam = React.memo(OurTeam);
const MemoizedLets = React.memo(Lets);

const Services = () => {
  return (
    <Grid sx={{ userSelect: "none" }}>
      <Suspense fallback={<CircularProgress />}>
        <Box>
          <MemoizedLets />
        </Box>
        <Box>
          <MemoizedOurServices />
        </Box>
        <Box>
          <MemoizedOurMission />
        </Box>
        <Box
          bgcolor="#FFFFFF"
          sx={{ whiteSpace: "nowrap", overflow: "hidden", display: "block" }}
          mb={8}
        >
          <MemoizedCarousel />
        </Box>
        <Box>
          <MemoizedTechnologyStack />
        </Box>
        <Box>
          <MemoizedDataCircle />
        </Box>
        <Box>
          <MemoizedOurTeam />
        </Box>
      </Suspense>
    </Grid>
  );
};

export default Services;
