/* eslint-disable jsx-a11y/alt-text */
import { useMemo, useState } from "react";
import {
  Box,
  Collapse,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import NeuraIcon from "../../assets/svg/icons/neuraicon.svg";
import MenuIcon from "../../assets/svg/icons/menuicon.svg";
import { ITabs, Tabs } from "./constants";
import { ERoutes } from "../../router/constants";
import { useTextStyle } from "../../contexts/TextStyleContext";
import { useLocation } from "react-router-dom";
import { baseFontProperties } from "../../theme/_config";

const Header = () => {
  const { texts } = useTextStyle();
  const isMobile = useMediaQuery("(max-width:900px)");
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuToggle = () => {
    setMenuOpen((prev) => !prev);
  };

  const navigate = (route: ERoutes) => {
    window.location.href = route;
  };

  const TABSRENDER = useMemo(() => {
    return (
      <Stack direction="row" spacing={2}>
        {Tabs.map((tab: ITabs) => (
          <Grid
            key={tab.label}
            py="12px"
            px="22px"
            onClick={() => navigate(tab.navigate)}
            sx={{
              transition: "all 0.3s ease-in-out",
              cursor: "pointer",
              userSelect: "none",
              border: "1px solid transparent",
              "&:hover": {
                border: "1px solid #808080",
              },
            }}
            bgcolor={
              location.pathname === `/${tab.navigate}`
                ? "#3C3C3C"
                : "transparent"
            }
            borderRadius="40px"
          >
            <Typography
              sx={[
                texts.button,
                {
                  color:
                    location.pathname === `/${tab.navigate}`
                      ? "#F7F7F7"
                      : baseFontProperties.color.body_dark,
                },
              ]}
            >
              {tab.label}
            </Typography>
          </Grid>
        ))}
      </Stack>
    );
  }, [location.pathname, texts.button]);

  const MENURENDER = useMemo(() => {
    return (
      <>
        <Box onClick={handleMenuToggle}>
          <img src={MenuIcon} />
        </Box>
      </>
    );
  }, []);

  return (
    <Grid width="100%">
      <Grid
        height={{
          xl: "100px",
          lg: "100px",
          md: "100px",
          sm: "60px",
          xs: "60px",
        }}
        width="100%"
        px={{ xl: "96px", lg: "96px", md: "96px", sm: "16px", xs: "16px" }}
        py={{ xl: "25px", lg: "25px", md: "25px", sm: "20px", xs: "20px" }}
        bgcolor="#F7F7F7"
      >
        <Grid display="flex" justifyContent="space-between" alignItems="center">
          <Grid
            onClick={() => {
              navigate(ERoutes.LANDINGPAGE);
            }}
            style={{ cursor: "pointer", userSelect: "none" }}
          >
            <img height={20} width={171} src={NeuraIcon} />
          </Grid>
          {isMobile ? MENURENDER : TABSRENDER}
        </Grid>
      </Grid>
      <Collapse
        in={menuOpen}
        style={{ backgroundColor: "#F7F7F7", width: "100%" }}
      >
        <Stack direction="column" width="100%" spacing={2} my={3}>
          {Tabs.map((tab: ITabs) => (
            <Grid
              display="flex"
              justifyContent="center"
              width="100%"
              key={tab.label}
              py="12px"
              px="22px"
              onClick={() => navigate(tab.navigate)}
              style={{ cursor: "pointer" }}
              bgcolor={
                location.pathname === `/${tab.navigate}`
                  ? "#3C3C3C"
                  : "transparent"
              }
            >
              <Typography
                sx={[
                  texts.button,
                  {
                    color:
                      location.pathname === `/${tab.navigate}`
                        ? "#F7F7F7"
                        : baseFontProperties.color.body_dark,
                  },
                ]}
              >
                {tab.label}
              </Typography>
            </Grid>
          ))}
        </Stack>
      </Collapse>
    </Grid>
  );
};

export default Header;
