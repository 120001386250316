import { baseFontProperties } from "./_config";

export type TextStyle = React.CSSProperties;

export interface TextStyles {
  [key: string]: TextStyle;
}

export interface TextStyleContextType {
  texts: typeof defaultTextStyles;
  truncateString: (str: string, num: number) => string;
}

export const defaultTextStyles = {
  
  Title_96: {
    color: baseFontProperties.color.white2,
    fontSize: baseFontProperties.sizes.size_96,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_112,
  },
  Title_82: {
    color: baseFontProperties.color.white2,
    fontSize: baseFontProperties.sizes.size_82,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_112,
  },
  Large_Display_Title: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_96,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_112,
  },
  Display_Title: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_64,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_78,
  },
  Lead_Large_Body: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_32,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_40,
  },
  Paragraph: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_20,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.karla,
    lineHeight: baseFontProperties.lineHeight.size_32,
  },
  Link_Body_Small: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_20,
  },
  Button: {
    color: baseFontProperties.color.text_inverted,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_26,
  },
  Body_Regular_24_Medium: {
    color: baseFontProperties.color.text_inverted,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_30,
  },
  button: {
    color: baseFontProperties.color.body_dark,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_26,
  },
  button_small: {
    color: baseFontProperties.color.grey,
    fontSize: baseFontProperties.sizes.size_14,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_22,
  },
  cardTitle120: {
    color: baseFontProperties.color.grey,
    fontSize: baseFontProperties.sizes.size_120,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_112,
  },
  big_title: {
    color: baseFontProperties.color.white2,
    fontSize: baseFontProperties.sizes.size_100,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_100,
  },
  ourteam_title: {
    color: baseFontProperties.color.white2,
    fontSize: baseFontProperties.sizes.size_24,
    fontWeight: baseFontProperties.weight.medium,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_42,
  },
  lets_text: {
    color: baseFontProperties.color.body_dark,
    fontSize: baseFontProperties.sizes.size_72,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_80,
  },
  unlock_text: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_96,
    fontWeight: baseFontProperties.weight.bold,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_96,
  },
  upload_text: {
    color: baseFontProperties.color.neutral_600,
    fontSize: baseFontProperties.sizes.size_16,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.inter,
    lineHeight: baseFontProperties.lineHeight.size_38,
  },
  text12: {
    color: baseFontProperties.color.body_dark,
    fontSize: baseFontProperties.sizes.size_12,
    fontWeight: baseFontProperties.weight.normal,
    fontFamily: baseFontProperties.family.montserrat,
    lineHeight: baseFontProperties.lineHeight.size_14,
  },
};
