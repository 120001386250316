import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// Extend the Palette and PaletteOptions interfaces to include custom colors
declare module "@mui/material/styles" {
  interface Palette {
    customColors: CustomPaletteColorOptions;
  }
  interface PaletteOptions {
    customColors?: CustomPaletteColorOptions;
  }
}

interface CustomPaletteColorOptions {
  blue: CustomBlueColorSet;
  grey: CustomGreyColorSet;
}

interface CustomBlueColorSet {
  normal: string;
  light?: string;
  dark?: string;
  white: string;
  lightHover: string;
  lightActive: string;
  normalHover: string;
  normalActive: string;
  darker: string;
  darkHover: string;
  darkActive: string;
}

interface CustomGreyColorSet {
  normal: string;
  white: string;
  lightHover: string;
  lightActive: string;
  normalHover: string;
  normalActive: string;
  bodyDark: string;
  darkHover: string;
  darkActive: string;
}

// Define your custom color palette
const customPalette: CustomPaletteColorOptions = {
  blue: {
    normal: "#748BFE",
    light: "#F1F3FF",
    dark: "#5768BF",
    white: "#e3f2fd",
    lightHover: "#EAEEFF",
    lightActive: "#D4DBFF",
    normalHover: "#687DE5",
    normalActive: "#5D6FCB",
    darker: "#1e3a8a",
    darkHover: "#465398",
    darkActive: "#343F72",
  },
  grey: {
    normal: "#ABABAB",
    white: "#F7F7F7",
    lightHover: "#F2F2F2",
    lightActive: "#E5E5E5",
    normalHover: "#9A9A9A",
    normalActive: "#898989",
    bodyDark: "#808080",
    darkHover: "#676767",
    darkActive: "#4D4D4D",
  },
};

// Create a theme instance
const theme = createTheme({
  palette: {
    primary: {
      main: customPalette.blue.normal,
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    customColors: customPalette,
  },
});

export default theme;
